import { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export default function Register() {
	function validatePassword() {
		const passwordInput = document.getElementById( 'password' );
		const confirmPasswordInput = document.getElementById( 'confirm-password' );
		const letter = document.getElementById( 'letter' );
		const capital = document.getElementById( 'capital' );
		const number = document.getElementById( 'number' );
		const length = document.getElementById( 'length' );
		const special = document.getElementById( 'special' );
		const submit = document.getElementById( 'submit' );
		const confirm = document.getElementById( 'confirm' );

		// Validate lowercase letters
		let lowerCaseLetters = /[a-z]/g;
		if ( passwordInput.value.match( lowerCaseLetters ) ) {
			letter.classList.add( 'hidden' );
		} else {
			letter.classList.remove( 'hidden' );
		}

		// Validate capital letters
		let upperCaseLetters = /[A-Z]/g;
		if ( passwordInput.value.match( upperCaseLetters ) ) {
			capital.classList.add( 'hidden' );
		} else {
			capital.classList.remove( 'hidden' );
		}

		// Validate numbers
		let numbers = /[0-9]/g;
		if ( passwordInput.value.match( numbers ) ) {
			number.classList.add( 'hidden' );
		} else {
			number.classList.remove( 'hidden' );
		}

		// Validate special chars
		let specialChars = /[!@#$%^&*]/g;
		if ( passwordInput.value.match( specialChars ) ) {
			special.classList.add( 'hidden' );
		} else {
			special.classList.remove( 'hidden' );
		}

		// Validate length letters
		if ( passwordInput.value.length >= 8 ) {
			length.classList.add( 'hidden' );
		} else {
			length.classList.remove( 'hidden' );
		}

		// Validate match passwords
		if ( passwordInput.value === confirmPasswordInput.value ) {
			confirm.classList.add( 'hidden' );
		} else {
			confirm.classList.remove( 'hidden' );
		}

		if ( letter.classList.contains( 'hidden' )
			&& capital.classList.contains( 'hidden' )
			&& number.classList.contains( 'hidden' )
			&& special.classList.contains( 'hidden' )
			&& length.classList.contains( 'hidden' )
			&& confirm.classList.contains( 'hidden' )
			&& getCaptchaKey() !== '' ) {
			submit.removeAttribute( 'disabled' );
		} else {
			submit.setAttribute( 'disabled', 'disabled' );
		}
	}

	function getParamFromURL( param ) {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams( queryString );
		const token = urlParams.get( param );

		return token;
	}

	function resetPassword( e ) {
		e.preventDefault();

		const body = {
			token: getParamFromURL( 'token' ),
			password: document.getElementById( 'password' ).value
		};

		fetch( 'https://company-api.7ldev.com/api/tenant-reset', {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'X-API-KEY': 'PdIdxrYHe7!omTPs'
			},
			body: JSON.stringify( body )
		} )
		.then( response => {
			if ( response.ok ) {
				document.getElementById( 'form-container' ).classList.add( 'hidden' );
				document.getElementById( 'thank-you' ).classList.remove( 'hidden' );
			} else {
				alert( response.status );
			}

			return response.json();
		} )
		.then( data => {
			console.log( data );
		} )
		.catch( ( error ) => {
			alert( error );
		} );
	}

	function onChange( value ) {
		localStorage.setItem( 'company-captcha-key', value );
		validatePassword();
	}

	function getCaptchaKey() {
		if ( localStorage.getItem( 'company-captcha-key' ) ) {
			return localStorage.getItem( 'company-captcha-key' );
		}

		return '';
	}

	useEffect( () => {
		localStorage.setItem( 'company-captcha-key', '' );
	}, [] );

	return ( <>
		<div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img className="mx-auto h-32 w-auto" src="/assets/logo.webp" alt="Your Company"/>
				<h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Reset your password</h2>
				<small className="mt-4 text-center block">Your password must be at least 8 characters long, containing a number, a lowercase letter, a capital letter and one special character (!@#$%^&*)</small>
			</div>

			<div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
				<div id="form-container" className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
					<form id="register-form" className="space-y-6" action="#" onSubmit={ ( e ) => {
						resetPassword( e );
					} }>
						<div>
							<label htmlFor="username" className="block text-sm font-medium text-gray-700"> Username/Email </label>
							<div className="mt-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 bg-gray-100 sm:text-sm">
								{ getParamFromURL( 'email' ) }
							</div>
						</div>

						<div>
							<label htmlFor="password" className="block text-sm font-medium text-gray-700"> New Password </label>
							<div className="mt-1">
								<input onChange={ () => {
									validatePassword();
								} } id="password" pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{6,}$" name="password" type="password" autoComplete="current-password" required className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"/>
							</div>
						</div>

						<div>
							<label htmlFor="confirm_password" className="block text-sm font-medium text-gray-700"> Confirm Password </label>
							<div className="mt-1">
								<input onChange={ () => {
									validatePassword();
								} } id="confirm-password" name="confirm_password" type="password" autoComplete="current-password" required className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"/>
							</div>
						</div>

						<ReCAPTCHA sitekey="6LdixkIiAAAAAJPG-P6hPhcvOkJJocYwkQTOOLiC" size="normal" type="image" onChange={ onChange }/>

						<div>
							<button id="submit" type="submit" disabled className="flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 hover:disabled:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-no-drop">
								Submit
							</button>
						</div>
					</form>

					<div id="message" className="mt-4 text-xs text-red-700">
						<p id="letter" className="hidden">Password must contain a <b>lowercase</b> letter</p>
						<p id="capital" className="hidden">Password must contain a <b>capital (uppercase)</b> letter</p>
						<p id="number" className="hidden">Password must contain a <b>number</b></p>
						<p id="special" className="hidden">Password must contain a <b>special character (!@#$%^&*)</b></p>
						<p id="length" className="hidden">Password must be minimum <b>8 characters</b></p>
						<p id="confirm" className="hidden">Passwords do not match</p>
					</div>
				</div>

				<div id="thank-you" className="hidden">
					<div className="rounded-md bg-green-50 p-4 shadow">
						<div className="flex">
							<div className="flex-shrink-0">
								<svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd"/>
								</svg>
							</div>
							<div className="ml-3">
								<h3 className="font-medium text-green-800">Reset completed</h3>
								<div className="mt-2 text-sm text-green-700">
									<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</> );
}
